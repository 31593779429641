import React from "react"
import "twin.macro"

type SectionHeaderProps = {
  title: string
}

export const SectionHeader1 = ({ title }: SectionHeaderProps) => {
  return (
    <h2 tw="inline-block text-3xl mb-2 pl-2 font-bold border-primary border-l-4">
      {title}
    </h2>
  )
}

export const SectionHeader2 = ({ title }: SectionHeaderProps) => {
  return (
    <h3 tw="inline-block text-2xl mb-2 font-semibold">
      {title}
    </h3>
  )
}
