import React from "react"
import "twin.macro"

import Layout from "../components/Layout"
import { SectionHeader1, SectionHeader2 } from "../components/SectionHeaders"
import { Section, SubSection, DefinitionList, DefinitionTerm, DefinitionDescription, Ul } from "../components/Trypography"
import Profile from "../images/profile.jpg"
import ProfileWebp from "../images/profile.webp"

type ExperienceItemProps = {
  rangeStart: string
  rangeEnd: string
  title: string
  contents?: string[]
}

const ExperienceItem = ({rangeStart, rangeEnd, title, contents}: ExperienceItemProps) => {
  return (
    <div tw="mt-6">
      <h5 tw="font-semibold text-gray-500">{rangeStart} - {rangeEnd}</h5>
      <h4 tw="text-xl font-semibold -mt-1">{title}</h4>
      {contents &&
        <ul tw="list-none">
          {contents.map((item, i) => <li key={i}>{item}</li>)}
        </ul>
      }
      </div>
  )
}

const experiences = [
  {
    rangeStart: "August 1 2018",
    rangeEnd: "Present",
    title: "Software Engineer, Human Dataware Lab., Nagoya, JAPAN",
  },
  {
    rangeStart: "February 1 2017",
    rangeEnd: "Present",
    title: "Web Developer, y2app (self-employed), Nagoya, JAPAN",
  },
  {
    rangeStart: "April 1 2017",
    rangeEnd: "March 31 2019",
    title: "Teaching Assistant, Daido University, Nagoya, JAPAN",
  },
  {
    rangeStart: "July 31 2017",
    rangeEnd: "September 1 2017",
    title: "Software Engineer Internship, Wantedly, Inc., Tokyo, JAPAN",
    contents: ["Develop news filtering system using word embeddings and machine learning."],
  },
]

const educations = [
  {
    rangeStart: "April 2019",
    rangeEnd: "March 2022",
    title: "Nagoya University, Graduate School of Informatics, Nagoya, JAPAN",
    contents: ["Ph.D. in Informatics"],
  },
  {
    rangeStart: "April 2017",
    rangeEnd: "March 2019",
    title: "Nagoya University, Graduate School of Informatics, Nagoya, JAPAN",
    contents: ["Master of Informatics in Intelligent Systems"],
  },
  {
    rangeStart: "April 2015",
    rangeEnd: "March 2017",
    title: "Nagoya University, Nagoya, JAPAN",
    contents: ["Bachelor of Engineering in Electrical and Electronic Engineering"],
  },
  {
    rangeStart: "August 2011",
    rangeEnd: "July 2012",
    title: "Tallwood High School , Virginia Beach, VA, USA",
    contents: ["As a foreign exchange student"],
  },
  {
    rangeStart: "April 2009",
    rangeEnd: " March 2015",
    title: "Toyota National College of Technology, Toyota, JAPAN",
    contents: ["Associate Degree in Electrical and Electronic System Engineering"],
  },
]

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div tw="mt-10 md:flex justify-center items-center gap-10">
        <picture>
          <source srcSet={ ProfileWebp } type="image/webp" tw="w-32 h-32 max-w-max rounded-full my-4 mx-auto md:mx-0" />
          <img src={ Profile } alt="profile photo" tw="w-32 h-32 max-w-max rounded-full my-4 mx-auto md:mx-0" />
        </picture>
        <ul tw="list-none">
          <li tw="text-lg font-semibold">Yusuke Sakai</li>
          <li>Employed and freelance programmer who loves to develop user interfaces and software</li>
          <li>PhD in Informatics</li>
          <li><span tw="font-bold mr-1">Email</span> contact[at]yfsakai.com</li>
        </ul>
      </div>

      <Section>
        <SectionHeader1 title="Skills" />

        <SubSection>
          <SectionHeader2 title="Languages" />
          <DefinitionList>
            <DefinitionTerm>Japanese</DefinitionTerm>
            <DefinitionDescription>native language</DefinitionDescription>

            <DefinitionTerm>English</DefinitionTerm>
            <DefinitionDescription>limited working proficiency (ILR scale)</DefinitionDescription>
          </DefinitionList>
        </SubSection>

        <SubSection>
          <SectionHeader2 title="Programming (and Related) Languages" />
          <DefinitionList>
            <DefinitionTerm>Proficient</DefinitionTerm>
            <DefinitionDescription>TypeScript/JavaScript, Python, HTML, CSS</DefinitionDescription>

            <DefinitionTerm>Experienced</DefinitionTerm>
            <DefinitionDescription>Dart, C, C#, C++, Java, PHP</DefinitionDescription>

            <DefinitionTerm>Learning</DefinitionTerm>
            <DefinitionDescription>Rust, Golang</DefinitionDescription>
          </DefinitionList>
        </SubSection>

        <SubSection>
          <SectionHeader2 title="Experienced Frameworks and Tools" />
          <DefinitionList>
            <DefinitionTerm>Web Front-End</DefinitionTerm>
            <DefinitionDescription>React.js, Vue.js</DefinitionDescription>

            <DefinitionTerm>Web Back-End</DefinitionTerm>
            <DefinitionDescription>FastAPI, Django</DefinitionDescription>

            <DefinitionTerm>Webpages</DefinitionTerm>
            <DefinitionDescription>Gatsby, Jekyll, Hugo, Wordpress</DefinitionDescription>

            <DefinitionTerm>Native Apps</DefinitionTerm>
            <DefinitionDescription>Flutter, Electron, Qt</DefinitionDescription>

            <DefinitionTerm>Games</DefinitionTerm>
            <DefinitionDescription>Unity</DefinitionDescription>

            <DefinitionTerm>Machine Learning</DefinitionTerm>
            <DefinitionDescription>Tensorflow, Keras</DefinitionDescription>

            <DefinitionTerm>Infrastructure</DefinitionTerm>
            <DefinitionDescription>Terraform, Kubernetes, Docker, AWS</DefinitionDescription>

            <DefinitionTerm>Document</DefinitionTerm>
            <DefinitionDescription>TeX, Markdown, MS Office</DefinitionDescription>
          </DefinitionList>
        </SubSection>
      </Section>

      <Section>
        <SectionHeader1 title="Qualifications" />
        <div tw="my-3" />
        <Ul>
          <li>準中型自動車免許 (2012)</li>
          <li>TOEIC Score 905 (2013)</li>
          <li>大型自動二輪車免許 (2014)</li>
          <li>第二種電気工事士 (2021)</li>
          <li>宅地建物取引士 (2024)</li>
        </Ul>
      </Section>

      <Section>
        <SectionHeader1 title="Experience" />
        {experiences.map((item, i) => <ExperienceItem key={i} {...item} />)}
      </Section>

      <Section>
        <SectionHeader1 title="Education" />
        {educations.map((item, i) => <ExperienceItem key={i} {...item} />)}
      </Section>

      <Section>
        <SectionHeader1 title="Publication" />

        <SubSection>
          <SectionHeader2 title="Journal Papers" />
          <Ul>
            <li>Yusuke Sakai, Hiromi Morita, Yoshio Ishiguro, Takanori Nishino, Kazuya Takeda, SecretSign: A Method of Finding a Specific Vehicle Privately and Quickly using Flashing Lights, IEEE Intelligent Transportation Systems Magazine, January 2022.</li>
            <li>Yusuke Sakai, Yoshio Ishiguro, Kento Ohtani, Takanori Nishino, Kazuya Takeda, "FollowSelect: Path-Based Menu Interaction for Intuitive Navigation", Journal of Information Processing Society of Japan, October 2021 (in Japanese).</li>
          </Ul>
        </SubSection>

        <SubSection>
          <SectionHeader2 title="Conferences" />
          <Ul>
            <li>Yusuke Sakai, Yoshio Ishiguro, Akira Tamamori, Kei Hiroi, Nobuo Kawaguchi, Kazuya Takeda, "An Semi-Automatic Dialogue System between an Operator and Multiple Users of an Empirical Study for Supporting Elderly People", HUMAN COMMUNICATION GROUP SYMPOSIUM 2018, HCG2018-C-1-1, pp. 1-7, December 2018 (in Japanese).</li>
            <li>Yusuke Sakai, Hiromi Morita, Yoshio Ishiguro, Takanori Nishino, Kazuya Takeda, "SecretSign: A Method of Finding an Off-line Target Object without Revealing the Target to Observers", 2018 21st International Conference on Intelligent Transportation Systems (ITSC), pp. 3651-3656, November 2018.</li>
          </Ul>
        </SubSection>

        <SubSection>
          <SectionHeader2 title="Demos and Posters" />
          <Ul>
            <li>榮井 優介，石黒 祥生，西野 隆典，武田 一哉, "FollowSelect: 準備動作が必要な機器の利用に適した経路追従型メニュー選択手法", IPSJ Interaction 2020 インタラクティブ発表, 2A-02, pp. 512-516, March 2020 (demo, in Japanese).</li>
            <li>Yusuke Sakai, Toshimitsu Watanabe, Yoshio Ishiguro, Takanori Nishino, Kazuya Takeda, "Effects on User Perception of a 'Modified' Speed Experience Through In-Vehicle Virtual Reality", Automotive User Interfaces and Interactive Vehicular Applications (AutomotiveUI '19) Work-in-Progress (poster).</li>
            <li>榮井 優介，石黒 祥生，西野 隆典，武田 一哉, "CarBuddy: 加速度情報とスケルトン追跡による車両周辺ユーザの特定と意図推定", IPSJ Interaction 2019 インタラクティブ発表, 2A-06, pp. 489-493, March 2019 (demo, in Japanese).</li>
          </Ul>
        </SubSection>

        <SubSection>
          <SectionHeader2 title="Awards" />
          <Ul>
            <li>大学院研究奨励賞, 公益社団法人 自動車技術会, March 2022.</li>
            <li>挑戦的研究賞, 第22回東海地区音声関連研究室修士論文中間発表会, August 2018.</li>
          </Ul>
        </SubSection>
      </Section>
    </Layout>
  )
}

export default IndexPage
